import { gql } from '@apollo/client';

export const CREATE_PRODUCT_MUTATION = gql`
  mutation createProduct(
    $brandName: String!
    $molecules: String!
    $packSize: String!
    $manufacturer: String!
    $description: String
    $sellingPrice: Float
    $quantity: Float
    $logo: String
    $productVariant: String
    $productClass: String
    $therapeuticType: String!
  ) {
    createProduct(
      brandName: $brandName
      molecules: $molecules
      manufacturer: $manufacturer
      packSize: $packSize
      description: $description
      sellingPrice: $sellingPrice
      quantityInStock: $quantity
      imageLink: $logo
      productVariant: $productVariant
      productClass: $productClass
      therapeuticType: $therapeuticType
    ) {
      message
      product {
        id
        status
      }
    }
  }
`;

export const UPDATE_PRODUCT_MUTATION = gql`
  mutation updateProduct(
    $productId: String!
    $brandName: String!
    $molecules: String!
    $manufacturer: String!
    $packSize: String!
    $description: String
    $sellingPrice: Float
    $quantity: Float
    $logo: String
    $productVariant: String
    $productClass: String
    $therapeuticType: String!
  ) {
    updateProduct(
      productId: $productId
      brandName: $brandName
      molecules: $molecules
      manufacturer: $manufacturer
      description: $description
      packSize: $packSize
      sellingPrice: $sellingPrice
      quantityInStock: $quantity
      imageLink: $logo
      productVariant: $productVariant
      productClass: $productClass
      therapeuticType: $therapeuticType
    ) {
      message
      product {
        id
        status
      }
    }
  }
`;

export const UPDATE_PRODUCTS_STATUS_MUTATION = gql`
  mutation updateProductStatus($productIds: [String], $status: String!) {
    updateProductStatus(productIds: $productIds, status: $status) {
      message
    }
  }
`;

export const UPDATE_PRODUCTS_QUANTITY_MUTATION = gql`
  mutation updateProductQuantity($products: [ProductQuantityInputType!]!) {
    updateProductQuantity(products: $products) {
      message
    }
  }
`;

export const ARCHIVE_PRODUCTS_MUTATION = gql`
  mutation archiveProduct($productIds: [String]) {
    archiveProduct(productIds: $productIds) {
      message
    }
  }
`;

export const CREATE_BATCH_MUTATION = gql`
  mutation createBatch(
    $batchNo: String
    $dateReceived: String
    $expiryDate: String!
    $productId: String
    $quantity: Float
  ) {
    createBatch(
      batchNo: $batchNo
      dateReceived: $dateReceived
      expiryDate: $expiryDate
      productId: $productId
      quantity: $quantity
    ) {
      message
      batch {
        id
        batchNo
      }
    }
  }
`;

export const UPDATE_BATCH_MUTATION = gql`
  mutation updateBatch(
    $batchId: String!
    $expiryDate: String!
    $quantity: Float
  ) {
    updateBatch(
      batchId: $batchId
      expiryDate: $expiryDate
      quantity: $quantity
    ) {
      message
      batch {
        id
      }
    }
  }
`;
export const DELETE_BATCH_MUTATION = gql`
  mutation deleteBatch($batchIds: [ID]!) {
    deleteBatch(batchIds: $batchIds) {
      message
    }
  }
`;
export const DELETE_PRODUCT_VERSION_MUTATION = gql`
  mutation deleteProductVersion($productId: Int!) {
    deleteProductVersion(productId: $productId) {
      message
    }
  }
`;

export const MAKE_ONLY_SUPPLY_CHAIN_PRODUCT_VISIBLE = gql`
  mutation makeOnlySupplyChainProductVisible {
    makeOnlySupplyChainProductVisible {
      message
    }
  }
`;

export const UPDATE_PRODUCT_BATCH_STATUS = gql`
  mutation updateProductBatchStatus($batchIds: [ID]!, $status: String!) {
    updateProductBatchStatus(batchIds: $batchIds, status: $status) {
      message
    }
  }
`;

export const PRODUCT_SETTING_DISPLAY_MUTATION = gql`
  mutation productDisplaySettings(
    $enabled: Boolean!
    $displayOption: String!
    $products: [ProductSettingsInputType]
  ) {
    productDisplaySettings(
      enabled: $enabled
      displayOption: $displayOption
      products: $products
    ){
      message
    }
  }
`;

export const UPDATE_BATCH_SHORTDATED_STATUS = gql`
  mutation markBatchAsShortDated($batchId: ID!, $shortDated: Boolean!) {
    markBatchAsShortDated(batchId: $batchId, shortDated: $shortDated) {
    message
    }
  }
`;
