import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, DialogContent, Slide, Grid, InputAdornment
} from '@mui/material';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import DropZone from '../../logoDropzone';
import CropImageContainer from '../../logoProcess/cropImageContainer';
import InitialImage from '../../logoProcess/initialImage';
import CustomButton from '../../../customComponents/customButton';
import SavePopper from './savePopper';
import CustomInputBase from '../../../customComponents/customInputBase';
import CustomSelectInputBase from '../../../customComponents/customSelectInputBase';
import {
  CDialog, DialogTitleText, DialogTitleSubText, CircularProgressLoader, UploadGrid,
  GridWrapper, CurrencyTypo, CloseIcon
} from './individualProductDialog.styles';
import {
  CREATE_PRODUCT_MUTATION, UPDATE_PRODUCT_MUTATION
} from '../../../../mutations/products';

import ProductActionTypes from '../../../../providers/reducers/product/productTypes';
import { useStateValue } from '../../../../providers/stateProvider';
import { JSONParse } from '../../../../utils/json';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const IndividualProductDialog = ({
  dialogOpen, closeDialog,
  updateOrder
}) => {
  const initialState = {
    brandName: '',
    molecules: '',
    packSize: '',
    businessId: null,
    image: '',
    id: '',
    description: '',
    sellingPrice: '',
    quantity: '',
    productVariant: '',
    productClass: 'drug',
    manufacturer: '',
    therapeuticType: ''
  };

  const initialErrorState = {
    brandNameError: false,
    moleculesError: false,
    sellingPriceError: false,
    quantityError: false,
    packSizeError: false,
    descriptionError: false,
    productClassError: false,
    manufacturerError: false,
    therapeuticTypeError: false
  };

  const [state, setState] = useState(initialState);
  const [files, setFiles] = useState(null);
  const [finalImage, setFinalImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [saveButtonEl, setSaveButtonEl] = useState(null);
  const [editing, setEditing] = useState(false);
  const [showInitialImage, setShowInitialImage] = useState(false);
  const [manuHelperText, setManuHelperText] = useState('Manufacturer name required');
  const [moleculeHelperText, setMoleculeHelperText] = useState('Molecule name required');
  const [theraHelperText, setTheraHelperText] = useState('Therapeutic Type name required');

  const [{
    product: { productRow, refetch },
    user: { session: { businessType } }
  }, dispatch] = Object.values(useStateValue());

  const [createProduct] = useMutation(CREATE_PRODUCT_MUTATION);
  const [updateProduct] = useMutation(UPDATE_PRODUCT_MUTATION, {
    refetchQueries: ['productActionTimelines']
  });

  const isWholesaler = businessType === 'WHOLESALER';

  const {
    brandNameError, moleculesError, packSizeError, sellingPriceError, quantityError, descriptionError,
    productClassError, manufacturerError, therapeuticTypeError
  } = errorState;

  useEffect(() => {
    if (productRow) {
      setEditing(true);
      setShowInitialImage(true);
      let prodClass = '';
      const {
        productClass,
        molecule,
        therapeuticType,
        meta
      } = productRow;
      const obj = JSON.parse(meta);
      const {
        pack_size: packSize, description, image, product_variant: productVariant
      } = obj;

      const moleculeStr = molecule ? JSONParse(molecule).toString().replaceAll(',', ', ') : '';

      if (productClass) {
        prodClass = productClass.toLowerCase();
      }
      setState((_state) => ({
        ..._state, ...productRow, id: productRow.id, sellingPrice: productRow.sellingPrice, productClass: prodClass,
        quantity: productRow.quantityInStock, molecules: moleculeStr, packSize, description, image, productVariant,
        therapeuticType
      }));
    }
  }, [productRow]);

  const handleCloseDialog = () => {
    if (editing) setEditing(false);
    setState(initialState);
    dispatch({
      type: ProductActionTypes.UPDATE_PRODUCT,
      payload: { productRow: null }
    });
    setShowInitialImage(false);
    setFinalImage(null);
    closeDialog();
  };

  const handleFile = (filesData) => {
    setFiles(filesData);
  };

  const handleChangeInitialImage = () => {
    setShowInitialImage(false);
  };

  const handleDeleteInitialImage = () => {
    setShowInitialImage(false);
    setState({ ...state, image: '' });
  };

  const handleImageUpload = (file) => {
    const uploadPreset = process.env.PRODUCT_UPLOAD_PRESET;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);
    formData.append('api_key', `${process.env.API_KEY}`);
    formData.append('timestamp', (Date.now() / 1000) || 0);

    return new Promise((resolve, reject) => {
      fetch(process.env.CLOUDINARY_URL, {
        method: 'POST',
        body: formData
      }).then(async (res) => {
        const response = await res.json();
        if (response.error) {
          setLoading(false);
          toast.error(response.error?.message);
          return reject(response.error.message);
        }
        return resolve(response.secure_url);
      });
    });
  };

  const addProduct = (logo, addMore) => {
    const {
      brandName, molecules, description, packSize,
      sellingPrice, quantity, manufacturer,
      productVariant, therapeuticType,
      productClass
    } = state;

    const variables = {
      brandName,
      molecules,
      description,
      packSize,
      therapeuticType,
      sellingPrice: parseFloat(sellingPrice),
      logo,
      productClass,
      productVariant,
      manufacturer,
      ...(isWholesaler && { quantity: parseFloat(quantity) })
    };

    createProduct({ variables })
      .then(({ data }) => {
        const { message } = data?.createProduct || {};
        toast.success(message);
        if (!updateOrder) {
          // refetch(); throwing error and causing success to show
        }
        if (!addMore) {
          handleCloseDialog();
        }
      })
      .catch((e) => {
        toast.error(e.message);
      })
      .finally(() => {
        setLoading(false);
        refetch();
      });
  };

  const editProduct = (logo) => {
    const {
      brandName, molecules, description, packSize,
      sellingPrice, quantity, manufacturer,
      id, productVariant, therapeuticType,
      productClass
    } = state;

    const variables = {
      productId: id,
      brandName,
      molecules,
      description,
      packSize,
      therapeuticType,
      sellingPrice: parseFloat(sellingPrice),
      logo,
      productClass,
      productVariant,
      manufacturer,
      ...(isWholesaler && { quantity: parseFloat(quantity) })
    };

    updateProduct({ variables })
      .then(({ data }) => {
        const { message } = data?.updateProduct || {};
        toast.success(message);
        refetch();
        handleCloseDialog();
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = async (addMore = false) => {
    setSaveButtonEl(null);
    setLoading(true);
    const logoPlaceholder = 'https://res.cloudinary.com/health-id/image/upload/v1594134267/Placeholders/Product_Placeholder.png';
    const alreadyUploadedImage = state.image || logoPlaceholder;
    const logo = finalImage
      ? await handleImageUpload(finalImage)
      : alreadyUploadedImage;
    if (editing) return editProduct(logo);
    return addProduct(logo, addMore);
  };

  const handleSaveContinue = async () => {
    const addMore = true;
    await handleSave(addMore);
    setState(initialState);
    setFiles(null);
    setFinalImage(null);
  };
  const handleSaveButtonClick = (event) => {
    const {
      brandName, molecules, sellingPrice, packSize, description, quantity, productClass,
      manufacturer, therapeuticType
    } = state;

    const invalidTexts = ['None', 'Null', 'N.a', 'n/a', 'none', 'na', 'null', 'N/A', 'n.a', 'NIL', 'nil'];

    if (brandName === '') {
      toast.error('Brand Name is required');
    } else if (molecules === '') {
      toast.error('Molecule is required');
      setMoleculeHelperText('Molecule name required');
    } else if (sellingPrice === '') {
      toast.error('Selling Price is required');
    } else if (packSize === null || packSize === '') {
      toast.error('Pack Size field is required');
    } else if (description === '') {
      toast.error('Description field is required');
    } else if (productClass === '') {
      toast.error('Product class field is required');
    } else if (!manufacturer) {
      toast.error('Manufacturer field is required');
      setManuHelperText('Manufacturer name required');
    } else if (!therapeuticType) {
      toast.error('Therapeutic Type field is required');
      setTheraHelperText('Therapeutic Type name required');
    } else if (invalidTexts.includes(molecules)) {
      toast.error('Enter a valid Molecule name');
      setMoleculeHelperText('Enter a valid Molecule name');
    } else if (invalidTexts.includes(manufacturer)) {
      toast.error('Enter a valid Manufacturer name');
      setManuHelperText('Enter a valid Manufacturer name');
    } else if (invalidTexts.includes(therapeuticType)) {
      toast.error('Enter a valid Therapeutic Type name');
      setTheraHelperText('Enter a valid Therapeutic Type name');
    } else {
      if (brandName.length > 1 && !editing) {
        return setSaveButtonEl(saveButtonEl ? null : event.currentTarget);
      } if (brandName.length > 1 && editing) {
        return handleSave();
      }
    }
    return setErrorState({
      ...errorState,
      brandNameError: brandName === '',
      moleculesError: molecules === '' || invalidTexts.includes(molecules),
      packSizeError: packSize === '',
      sellingPriceError: sellingPrice === '',
      descriptionError: description === '',
      productClassError: productClass === '',
      manufacturerError: !manufacturer || invalidTexts.includes(manufacturer),
      therapeuticTypeError: !therapeuticType || invalidTexts.includes(therapeuticType)
    });
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'brandName':
      case 'molecules':
      case 'packSize':
      case 'sellingPrice':
      case 'description':
      case 'productClass':
      case 'manufacturer':
      case 'therapeuticType':
        return setErrorState({
          ...errorState,
          [`${name}Error`]: !(value.length > 0)
        });
      default:
        return null;
    }
  };

  const handleChangeuom = (event) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value
    });
  };

  const handleChangeProductVariant = (event) => {
    const { value } = event.target;
    let variantValue = '';
    if (value === 'On Request') {
      variantValue = 'onRequest';
    } else {
      variantValue = 'readilyAvailable';
    }
    setState({
      ...state,
      productVariant: variantValue
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const topFields = [
    {
      name: 'brandName', label: 'Product Name', required: true, placeholder: 'Enter Product Name',
      error: brandNameError, helperText: 'Product name required', secured: false, show: true
    },
    {
      name: 'molecules', label: 'Molecule', required: true, placeholder: 'Enter Molecule Name',
      error: moleculesError, helperText: moleculeHelperText, secured: false, show: true,
    },
  ];

  const middleFields = [
    {
      name: 'therapeuticType', label: 'Therapeutic Type', required: true, placeholder: 'Enter Therapeutic Type',
      show: true, secured: false, helperText: theraHelperText, error: therapeuticTypeError
    }
  ];

  const bottomFields = [
    {
      name: 'packSize', label: 'Pack Size', secured: false, show: true, placeholder: 'Enter Pack Size',
      error: packSizeError, helperText: 'Pack size required', required: true
    },
    {
      name: 'manufacturer', label: 'Manufacturer', required: true, placeholder: 'Enter Manufacturer',
      error: manufacturerError, helperText: manuHelperText, secured: false, show: true
    },
    {
      name: 'productVariant', label: 'Product Variant', options: ['On Request', 'Readily Available'],
      secured: false, show: true, onChange: handleChangeProductVariant, placeholder: 'Select'
    },
    {
      name: 'sellingPrice', label: 'Selling price', secured: false, show: true, placeholder: 'Enter Selling price', type: 'number',
      required: true, error: sellingPriceError
    },
    {
      name: 'quantity', label: 'Quantity', secured: false, show: isWholesaler, placeholder: 'Enter Quantity', type: 'number',
      required: false, error: quantityError, isTooltip: true, tooltipHeader: 'Quick Note', tooltipDesc: 'When you enter quantity for this product, your buyer will be able to see quantity on the product card, but if you don’t add quantity, no quantity  will be displayed for the buyers.',
      tooltipPlacement: 'top'
    },
    {
      name: 'productClass', label: 'Product Class', options: ['drug', 'non_drug'], secured: false, show: true,
      onChange: handleChangeuom, placeholder: 'Select',
      error: productClassError, helperText: 'Product class is required', required: true
    },
  ];

  const returnInputProps = (name) => {
    switch (name) {
      case 'itemPrice':
      case 'rpp':
        return {
          startAdornment: (
            <InputAdornment position="start">
              <CurrencyTypo>₦</CurrencyTypo>
            </InputAdornment>
          )
        };
      default:
        return null;
    }
  };

  const returnValue = (name, value) => {
    switch (name) {
      case 'sellingPrice':
      case 'quantity':
        return value && Number(value);
      default:
        return value;
    }
  };

  const returnTextField = (field) => {
    const {
      name: fieldName, label, required, error, helperText, isLoading, secured, placeholder, onChange, val, type, readOnly, isTooltip, tooltipDesc, tooltipHeader,
      tooltipPlacement
    } = field;
    const value = state[fieldName];
    if ([
      'productVariant', 'productClass'
    ].includes(fieldName)) {
      return (
        <CustomSelectInputBase
          field={field}
          value={val || value}
          disabled={secured}
          placeholder={placeholder}
          handleChange={onChange || handleChange}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
          showCheckBox={false}
          error={error || false}
          helperText={error && helperText}
          required={required}
        />
      );
    }
    return (
      <CustomInputBase
        label={label}
        value={returnValue(fieldName, value)}
        size="small"
        type={type || 'text'}
        onWheel={(e) => { e.target.blur(); }}
        error={error || false}
        helperText={error && helperText}
        loading={isLoading}
        required={required}
        disabled={secured}
        name={fieldName}
        onChange={handleChange}
        placeholder={placeholder}
        InputProps={returnInputProps(fieldName)}
        cSize="lg"
        readOnly={readOnly}
        isTooltip={isTooltip}
        tooltipDesc={tooltipDesc}
        tooltipHeader={tooltipHeader}
        tooltipPlacement={tooltipPlacement}
      />
    );
  };
  return (
    <>
      <CDialog
        data-testid="product-dialog"
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <GridWrapper container>
          <Grid container>
            <Grid item container xs={6}>
              <DialogTitleText>
                {editing ? 'Edit Individual Product' : 'Create Individual Product'}
              </DialogTitleText>
              <DialogTitleSubText>
                {editing
                  ? 'Edit the form to update product information'
                  : 'Add Product information to the fields in the form'}
              </DialogTitleSubText>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <CloseIcon onClick={handleCloseDialog} />
            </Grid>
          </Grid>
          <DialogContent>
            <UploadGrid container item>
              {showInitialImage ? (
                <InitialImage
                  image={state.image}
                  handleImageChange={handleChangeInitialImage}
                  handleImageDelete={handleDeleteInitialImage}
                />
              ) : (files && files.length ? (
                <CropImageContainer
                  files={files}
                  handleFile={handleFile}
                  handleFinalImage={setFinalImage}
                />
              ) : (
                <DropZone
                  handleFile={handleFile}
                />
              ))}
            </UploadGrid>
            <Grid container spacing={2}>
              {topFields.map((field) => (
                <Grid item key={field?.name} xs={6}>{returnTextField(field)}</Grid>
              ))}
              <Grid item xs={12} style={{ paddingBottom: '8px' }}>
                <CustomInputBase
                  multiline
                  minRows={3}
                  label="Description"
                  placeholder="Describe Product"
                  name="description"
                  required
                  value={state.description}
                  helperText="Description required"
                  onChange={handleChange}
                  error={descriptionError || false}
                />
              </Grid>
            </Grid>
            <Grid container style={{ padding: '8px 0' }}>
              {middleFields.filter((item) => item.show).map((field) => (
                <Grid item key={field.name} xs={12}>{returnTextField(field)}</Grid>
              ))}
            </Grid>
            <Grid container spacing={2} style={{ paddingTop: '8px' }}>
              {bottomFields.filter((item) => item.show).map((field) => (
                <Grid item key={field?.name} xs={6}>{returnTextField(field)}</Grid>
              ))}
            </Grid>
          </DialogContent>

          <DialogActions>
            <CustomButton
              type="tertiary"
              header
              style={{ width: '11rem', height: '2.7rem', marginRight: '1.2rem' }}
              onClick={handleCloseDialog}
            >
              Cancel
            </CustomButton>
            <CustomButton
              type="primaryStyles"
              header
              onClick={handleSaveButtonClick}
              disabled={loading}
              style={{ width: '11rem', height: '2.7rem' }}
            >
              {loading ? (
                <CircularProgressLoader
                  disableShrink
                  size={22}
                  thickness={5}
                />
              ) : editing ? 'Save Changes' : 'Save & ....'}
            </CustomButton>
          </DialogActions>
        </GridWrapper>
      </CDialog>

      <SavePopper
        saveButtonEl={saveButtonEl}
        setSaveButtonEl={setSaveButtonEl}
        handleSave={handleSave}
        handleSaveContinue={handleSaveContinue}
      />
    </>
  );
};

IndividualProductDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  updateOrder: PropTypes.bool,
};

IndividualProductDialog.defaultProps = {
  updateOrder: false
};

export default IndividualProductDialog;
